import "../home/Home.css";
import React from "react"
import {Link, Outlet} from "react-router-dom"
import Logo from "../../images/logo.png"
import LogoLight from "../../images/footer/logo-light.png";
import IosDownload from "../../images/footer/ios-download.png";
import AndroidDownload from "../../images/footer/android-download.png";


export default () => {
    const iosUrl = "https://apps.apple.com/cn/app/%E4%B8%AD%E6%B5%8E%E7%BB%8F%E7%BB%9C%E7%A9%B4%E4%BD%8D/id1554030035?platform=iphone";
    const androidUrl = "https://acupoint-resource.oss-cn-shenzhen.aliyuncs.com/apk/%E4%B8%AD%E6%B5%8E%E7%BB%8F%E7%BB%9C%E7%A9%B4%E4%BD%8D%20latest.apk";

    return <div className="page-home">
        <nav className="nav-wrap">
            <div className="nav">
                <img src={Logo} className="logo" draggable={false}/>
                <div className="menu">
                    <Link to="/" className="item">首页</Link>
                    <a href="/medicines" className="item">药品中心</a>
                    <a href="/#footer" duration={500} className="item">关于我们</a>
                </div>
            </div>
        </nav>
        <Outlet/>
        <div className="footer" id="footer">
            <div>
                {/*<img src={LogoLight} draggable={false}/>*/}
                <div className="contact">
                    <span>电话：4000739008</span>
                    <span>邮箱：zj@nineton.cn</span>
                    <span>联系地址：重庆市渝北区洪湖西路24号B栋11楼</span>
                </div>
                <div className="scan">
                    <div className="qrcode"/>
                    <div className="downloads">
                        <a href={iosUrl} target="_blank"><img src={IosDownload} draggable={false}/></a>
                        <a href={androidUrl} target="_blank"><img src={AndroidDownload} draggable={false}/></a>
                    </div>
                </div>
            </div>
            <p>www.lighttcm.com | All rights reserved. © 2021-2023 | 重庆知源健康管理有限公司 |
                <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"> 渝ICP备2021007722号 </a>
                |
                <a target="_blank" href="/images/lighttcm-certificate.png"> 互联网药品信息服务资格证书:（渝)-经营性-2023-0019</a>
                |
                <a target="_blank" href="/images/lighttcm-publication.png"> 出版物经营许可证</a>
            </p>
        </div>
    </div>
}